import { Link, PageProps } from 'gatsby'
import Layout from '../../components/molecules/layout'
import Seo from '../../components/molecules/seo'
import React from 'react'
import { useEffect } from 'react'
import { ClerkLoaded, SignedIn, SignedOut } from '@clerk/clerk-react'

const Clients = (props: PageProps) => {
  const title = 'clients'

  useEffect(() => {
    document?.getElementById('client-dropdown')?.classList?.add('active')
  }, [])

  return (
    <>
      <Layout>
        <Seo title={title} />
        <section className="hero is-primary">
          <div className="hero-body">
            <h1 className="page-title">{title}</h1>
          </div>
        </section>
        <section className="section pricing-section">
          <div className="container is-widescreen">
            <div className="columns">
              <div className="column is-full"></div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <main className="content">
                  <ClerkLoaded>
                    <div className="message">
                      <div className="message-body">
                        <SignedIn>
                          visit{' '}
                          <a href={`${process.env.GATSBY_PTN_DX}/`}>
                            your dashboard
                          </a>{' '}
                          for detailed installation instructions
                        </SignedIn>
                        <SignedOut>
                          detailed client installation instructions are
                          available on your dashboard{' '}
                          <a
                            href={`https://${process.env.GATSBY_CLERK_SIGN_UP_URL}/`}
                          >
                            after signing up
                          </a>
                        </SignedOut>
                      </div>
                    </div>
                  </ClerkLoaded>

                  <h2 id="logseq">kinopio</h2>
                  <p>
                    phonetonote's kinopio integration is available through{' '}
                    <a href={`${process.env.GATSBY_PTN_DX}/`}>your dashboard</a>{' '}
                    and works with your kinopio api key.
                  </p>

                  <h2 id="logseq">logseq</h2>
                  <p>
                    phonetonote's logseq client is available in the logseq
                    marketplace, search <strong>logseq-phonetonote</strong> to
                    find it. configuration options are stored in the plugin's
                    options. see{' '}
                    <Link to="/changelog/logseq-plugin-v0-3-0">
                      the release notes
                    </Link>{' '}
                    for more information.
                  </p>

                  <h2 id="roam">roam research</h2>
                  <p>
                    phonetonote's roam client is installed as a roam/js plugin.{' '}
                    <Link to="/changelog/more-configuration-options-in-roam-client/">
                      configuration options
                    </Link>{' '}
                    are edited and stored directly in your roam graph, on a new{' '}
                    <code>roam/js/ptr</code> page.
                  </p>
                  <h2 id="obsidian">obsidian</h2>
                  <p>
                    we do not have a native obsidian client yet, but you can use
                    the "append to file" zapier action, in combination with our{' '}
                    <Link to="/pages/integrations">zapier integrations</Link> to
                    pull your messages into obsidian.
                  </p>
                  <h2 id="craft">craft</h2>
                  <p>
                    phonetonote's craft client is available to download as a
                    .craftx file from{' '}
                    <a href="https://github.com/phonetonote/phonetocraft-builds">
                      this repo
                    </a>
                    . the full source code is{' '}
                    <a href="https://github.com/phonetonote/phonetocraft">
                      available here
                    </a>
                    .
                  </p>
                  <p>
                    this client is in alpha version and does not yet support
                    attachments or configuration. this will be available soon.
                  </p>
                </main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Clients
